* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #000;
  font-family: Montserrat, sans-serif;
}

path {
  transition: 0.3s ease-in-out;
}

.area-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
  mix-blend-mode: overlay;
  opacity: 1;
}
.activeSectors {
  transition: 0.3s ease-in-out;
  fill: #FFFFFF;
  fill-opacity: 1;
  mix-blend-mode: overlay;
  opacity: 1;
  filter: blur(2px);
  cursor: pointer;
}

#sectors {
  fill-opacity: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.activeNavigationPoint {
  cursor: pointer;
  fill: #ffdd50;
  animation-name: zip-zip;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  animation-direction: alternate;
}

.navigationBackPoint {
  cursor: pointer;
  animation-name: zip-zip;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  animation-direction: alternate;
}

#navigation-line {
  fill-opacity: 0;
  stroke-width: 0.7px;
  stroke: #ffdd50;
}

.zoom {
  stroke: #ffdd50;
  stroke-width: 1.3px;
}

@keyframes zip-zip {
  0% {
    stroke: #ffdd50;
    stroke-width: 1.3px;
    filter: drop-shadow(0px 0px 0px #ffdd50);
  }
  100% {
    stroke: #ffdd50;
    stroke-width: 1.3px;
    filter: drop-shadow(0px 0px 8px #ffdd50);
  }
}

.zoom-active {
  fill: #ffdd50;
}
